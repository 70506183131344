import { Action, createReducer, on } from '@ngrx/store';
import { InternalToolsActions } from './internal-tools.actions';
import { FeatureFlagKeys } from './feature-flag-keys';

export const internalToolsFeatureKey = 'internalTools';

export interface FeatureFlag {
   description: string;
   enabled: boolean;
}

export interface FeatureFlags {
   [key: string]: FeatureFlag;
}

export interface State {
   featureFlags: FeatureFlags;
}

export const initFeatureFlags: FeatureFlags = {
   [FeatureFlagKeys.ROLE_TEMPLATES]: { description: 'Role Templates', enabled: false },
   [FeatureFlagKeys.HELP_SIDEBAR]: { description: 'In-app help sidebar', enabled: false },
   [FeatureFlagKeys.GOALS_DETAIL]: { description: 'Goals detail view', enabled: true },
   [FeatureFlagKeys.COMPETENCIES]: { description: 'Team member competencies', enabled: false },
   [FeatureFlagKeys.PERMISSION_GROUPS]: { description: 'Permission groups', enabled: true },
   [FeatureFlagKeys.MANAGER_NOTES]: { description: 'Manager notes', enabled: true },
};

export const internalToolsInitialState: State = {
   featureFlags: initFeatureFlags,
};

const internalToolsReducer = createReducer(
   internalToolsInitialState,
   on(InternalToolsActions.ToggleFeatureFlag, (state, { key }) => {
      const featureFlag = state.featureFlags[key];
      const toggled = {
         ...featureFlag,
         enabled: !featureFlag.enabled,
      };
      const featureFlags = {
         ...state.featureFlags,
         [key]: toggled,
      };
      return {
         ...state,
         featureFlags,
      };
   }),
   on(InternalToolsActions.SyncFeatureFlags, (state) => {
      const oldFeatureFlags = { ...state.featureFlags };
      const featureFlags = {};
      Object.keys(oldFeatureFlags).forEach((key) => {
         if (initFeatureFlags[key]) {
            featureFlags[key] = oldFeatureFlags[key];
         }
      });
      Object.keys(initFeatureFlags).forEach((key) => {
         if (!featureFlags[key]) {
            featureFlags[key] = initFeatureFlags[key];
         }
      });
      return {
         ...state,
         featureFlags,
      };
   })
);

export function reducer(state: State | undefined, action: Action) {
   return internalToolsReducer(state, action);
}
