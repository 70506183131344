import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInternalTools from './internal-tools.reducer';

export const selectInternalToolsState = createFeatureSelector<fromInternalTools.State>(
   fromInternalTools.internalToolsFeatureKey
);

const getFeatureFlags = createSelector(selectInternalToolsState, (state) => state.featureFlags);

export const featureFlagSelectors = {
   getFeatureFlags,
};
