<mat-card>
   <mat-card-title>Notes</mat-card-title>
   <mat-card-content>
      <div class="row new-comment">
         <ng-container *ngIf="canEdit">
            <div class="col-12 editor">
               <textarea
                  [froalaEditor]="configs['newComment']"
                  (froalaInit)="initFroala($event, 'newComment')"
                  [(ngModel)]="commentText"
                  (ngModelChange)="updateDirty($event)"
               ></textarea>
            </div>

            <div class="col-sm-6 col-md-4" *ngIf="linkTasks">
               <app-task-autocomplete
                  placeholder="Linked task"
                  (optionSelected)="setLinkedTask($event)"
               ></app-task-autocomplete>
            </div>
            <div class="col-12">
               <button
                  [disabled]="!commentText"
                  mat-stroked-button
                  color="primary"
                  (click)="addComment()"
               >
                  Add Note
               </button>
            </div>
         </ng-container>
      </div>
      <div class="row">
         <div class="col-md-3">
            <mat-form-field class="date-filter">
               <mat-label>Show comments from</mat-label>
               <mat-select
                  color="primary"
                  [(ngModel)]="dateFilter"
                  (selectionChange)="filterComments()"
               >
                  <mat-option [value]="0">All time</mat-option>
                  <mat-option [value]="7">Last 7 days</mat-option>
                  <mat-option [value]="14">Last 14 days</mat-option>
                  <mat-option [value]="30">Last 30 days</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="col-md-3">
            <mat-form-field class="comment-sort">
               <mat-select
                  color="primary"
                  [(ngModel)]="sortNewest"
                  (selectionChange)="sortComments()"
               >
                  <mat-option [value]="false">Oldest First</mat-option>
                  <mat-option [value]="true">Newest First</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
      </div>
      <ng-container *ngFor="let comment of filteredComments">
         <mat-divider></mat-divider>
         <div class="row comment">
            <div class="col-12 comment-container">
               <div
                  *ngIf="!showEdit(comment)"
                  class="comment-text"
                  [innerHTML]="comment.text | safeHtml"
               ></div>
               <div *ngIf="showEdit(comment)" class="comment-text">
                  <textarea
                     [froalaEditor]="configs[comment.id]"
                     (froalaInit)="initFroala($event, comment.id)"
                     [(froalaModel)]="comment.text"
                  ></textarea>
               </div>
               <div class="comment-buttons">
                  <ng-container *ngIf="canEditComment(comment)">
                     <ng-container *ngIf="showEdit(comment)">
                        <button mat-icon-button color="primary" (click)="saveEditComment(comment)">
                           <mat-icon>save</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEditComment(comment)">
                           <mat-icon>cancel</mat-icon>
                        </button>
                     </ng-container>
                     <ng-container *ngIf="!showEdit(comment)">
                        <button mat-icon-button color="primary" (click)="editComment(comment)">
                           <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deleteComment(comment)">
                           <mat-icon>delete</mat-icon>
                        </button>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <div class="col-12" *ngIf="linkTasks">
               <ng-container *ngIf="showEdit(comment)">
                  <div class="col-4 linked-task-edit">
                     <app-task-autocomplete
                        placeholder="Linked task"
                        [formCtrl]="getLinkedTaskFormCtrl(comment)"
                        (optionSelected)="updateLinkedTask($event, comment)"
                     ></app-task-autocomplete>
                  </div>
               </ng-container>
               <ng-container *ngIf="!showEdit(comment)">
                  <div class="linked-task" *ngIf="comment.taskId">
                     <mat-icon>list</mat-icon>
                     <div class="linked-task-name">
                        {{ comment.taskId | nameById : tasks }}
                     </div>
                  </div>
               </ng-container>
            </div>
            <div class="col-12">
               <span class="commenter">{{ comment.commenter }}</span>
               |
               {{ comment.timestamp | date : 'medium' }}
            </div>
         </div>
      </ng-container>
   </mat-card-content>
</mat-card>
