import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   SimpleChanges,
   OnChanges,
} from '@angular/core';
import { UserComment } from '@entities/user-comment';
import * as moment from 'moment';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AuthUser } from '@entities/auth-user';
import { froalaConfig } from '@app/shared/config/froala.config';
import { User } from '@entities/user';
import { UserRole } from '@entities/enums/user-role';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { v4 as uuid } from 'uuid';
import { StorageService } from '@app/shared/services/storage.service';
import { Task } from '@entities/task';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
@Component({
   selector: 'app-user-comments',
   templateUrl: './user-comments.component.html',
   styleUrls: ['./user-comments.component.scss'],
})
export class UserCommentsComponent implements OnInit, OnChanges {
   @Input() comments: UserComment[] = [];
   @Input('newest') sortNewest = true;
   @Input() canEdit = true;
   @Input() linkTasks = false;
   @Input() tasks: Task[] = [];
   @Output() commentsUpdated = new EventEmitter<UserComment[]>();
   @Output() initialized = new EventEmitter<UserCommentsComponent>();
   dirty = false;
   commentText: string;
   commentTaskId?: string;
   orgId: string;
   authUser: AuthUser;
   currentUser: User;
   dateFilter = 0;
   filteredComments: UserComment[] = [];

   editorOptions = {
      ...froalaConfig,
      placeholderText: 'Add a note',
      toolbarBottom: true,
      toolbarSticky: false,
   };

   editors = {};
   configs: Record<string, any> = {};
   editComments: UserComment[] = [];
   linkedTaskFormControls: Record<string, FormControl> = {};

   newCommentId = uuid();

   constructor(
      private userOrgFacade: UserOrgFacade,
      private dialogService: DialogService,
      private storageService: StorageService,
      private errorService: ErrorService
   ) {}

   ngOnInit(): void {
      if (this.comments) {
         this.sortComments();
      }

      this.userOrgFacade.authUser$.subscribe((user) => {
         this.authUser = user;
      });
      this.userOrgFacade.currentUser$.subscribe((user) => {
         this.currentUser = user;
      });
      this.userOrgFacade.selectedOrgId$.subscribe((id) => {
         this.orgId = id;
      });
      this.configs['newComment'] = this.generateFroalaConfig('newComment');
      this.initialized.emit(this);
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['comments'] || changes['newest']) {
         this.sortComments();
         this.updateDirty();
      }
   }

   saveAll() {
      if (this.commentText) {
         this.addComment();
      }
      this.editComments.forEach((comment) => {
         const editedComment = this.saveEditComment(comment);
      });
   }

   addComment() {
      const comment: UserComment = {
         id: this.newCommentId,
         text: this.commentText,
         timestamp: moment().toISOString(),
         commenter: `${this.authUser.firstName} ${this.authUser.lastName}`,
         commenterId: this.authUser.uid,
         taskId: this.commentTaskId,
      };
      this.comments.push(comment);
      this.sortComments();
      this.commentText = null;
      this.commentTaskId = null;
      this.commentsUpdated.emit(this.comments);
      this.newCommentId = uuid();
   }

   sortComments() {
      if (this.sortNewest) {
         this.comments = this.comments.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
      } else {
         this.comments = this.comments.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
      }
      this.filterComments();
   }

   filterComments() {
      this.filteredComments = this.comments.filter((comment) => {
         if (this.dateFilter === 0) {
            return true;
         } else {
            return moment().diff(moment(comment.timestamp), 'days') <= this.dateFilter;
         }
      });
   }

   canEditComment(comment: UserComment) {
      return (
         (this.canEdit && this.authUser?.uid == comment.commenterId) ||
         this.currentUser?.role == UserRole.Admin
      );
   }

   showEdit(comment: UserComment) {
      return (
         this.editComments.findIndex(
            (c) => c.timestamp == comment.timestamp && c.commenterId == comment.commenterId
         ) >= 0
      );
   }

   editComment(comment: UserComment) {
      this.editComments.push({ ...comment });
      comment.id = comment.id ?? uuid();
      this.configs[comment.id] = this.generateFroalaConfig(comment.id);
      if (this.linkTasks) {
         const formCtrl = this.linkedTaskFormControls[comment.id] ?? new FormControl();
         this.linkedTaskFormControls[comment.id] = formCtrl;
         if (comment.taskId) {
            const task = this.tasks.find((task) => task.id === comment.taskId);
            formCtrl.setValue(task);
         }
      }
      this.updateDirty();
   }

   saveEditComment(comment: UserComment) {
      this.editComments = this.editComments.filter(
         (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
      );
      this.commentsUpdated.emit(this.comments);
      this.updateDirty();
   }

   cancelEditComment(comment: UserComment) {
      const originalComment = this.editComments.find(
         (c) => c.timestamp == comment.timestamp && c.commenterId == comment.commenterId
      );
      this.editComments = this.editComments.filter(
         (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
      );
      this.comments = this.comments.filter(
         (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
      );
      this.comments.push(originalComment);
      this.sortComments();
      this.updateDirty();
      delete this.editors[comment.id];
      delete this.configs[comment.id];
   }

   initFroala(controls, id) {
      controls.initialize();
      this.editors[id] = controls.getEditor();
   }

   deleteComment(comment) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Comment?',
            message: 'Do you want to delete this comment?',
            confirm: 'Yes, delete',
            deny: 'No, cancel',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result == true) {
               this.comments = this.comments.filter(
                  (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
               );
               this.filterComments();
               this.commentsUpdated.emit(this.comments);
            }
         });
   }
   updateDirty(model?: any) {
      if (model) {
         this.dirty = true;
      } else {
         this.dirty = this.editComments.length > 0;
      }
   }

   setLinkedTask(event: MatAutocompleteSelectedEvent) {
      this.commentTaskId = event?.option?.value?.id;
   }

   updateLinkedTask(event: MatAutocompleteSelectedEvent, comment: UserComment) {
      comment.taskId = event?.option?.value?.id;
   }

   getLinkedTaskFormCtrl(comment: UserComment) {
      return this.linkedTaskFormControls[comment.id];
   }

   private generateFroalaConfig(id: string) {
      const commentId = id === 'newComment' ? this.newCommentId : id;
      return {
         ...this.editorOptions,
         events: {
            'image.beforeUpload': (images) => {
               const editor = this.editors[id];
               const path = `${this.orgId}/comments/${commentId}/${uuid()}`;
               this.storageService.upload(path, images[0]).subscribe({
                  next: (url) => {
                     editor.image.insert(url, null, null, editor.image.get());
                     editor.popups.hideAll();
                  },
                  error: (err) => {
                     this.errorService.handleError(err, 'Error uploading image');
                  },
               });
            },
         },
      };
   }
}
