<ng-container *ngIf="loaded">
   <app-user-comments
      *ngIf="managerNotes"
      [linkTasks]="true"
      [tasks]="tasks$ | async"
      [comments]="managerNotes.notes"
      (commentsUpdated)="saveNotes($event)"
      (initialized)="commentsInitialized($event)"
   ></app-user-comments>
   <div class="empty-state" *ngIf="!managerNotes">
      <div class="empty-state-message">No notes found</div>
      <button mat-flat-button color="primary" (click)="createNotes()">Create Notes</button>
   </div>
</ng-container>
<ng-container *ngIf="!loaded">
   <div class="loading">Loading...</div>
</ng-container>
