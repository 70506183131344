import { adminSelectors } from '@app/admin/state/admin.selectors';
import { UserOrgState } from './user-org.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserRole } from '@entities/enums/user-role';

export const getUserOrgState = createFeatureSelector<UserOrgState>('userOrg');

export const getUsersForAccount = createSelector(getUserOrgState, (state) => state.usersForAccount);

export const getOrgsForUser = createSelector(getUserOrgState, (state) => state.userOrganizations);

export const getOrganizationId = createSelector(getUserOrgState, (state) => state.selectedOrgId);

export const getOrganization = createSelector(getUserOrgState, (state) =>
   state.userOrganizations.find((o) => o.organizationId == state.selectedOrgId)
);

export const getOverview = createSelector(getOrganization, (org) => (org ? org.overview : null));

export const getCurrentUserId = createSelector(getUserOrgState, (state) => state.currentUserId);

export const getCurrentUser = createSelector(getUserOrgState, (state) =>
   state.usersForAccount.find((u) => u.id == state.currentUserId)
);

export const getCurrentUserIsAdmin = createSelector(
   getCurrentUser,
   (user) => user.role === UserRole.Admin || user.isAdmin
);

export const getCurrentUserTeamMemberId = createSelector(
   getCurrentUser,
   (user) => user?.teamMemberId
);
