<mat-form-field>
   <input
      matInput
      type="text"
      name="teamMemberAutocomplete"
      [formControl]="formCtrl"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      (focus)="onFocus()"
      (keyup.enter)="selectFirstOption()"
      [required]="required"
   />
   <span *ngIf="showClear && selected && canEdit" class="clear-selection" (click)="clear()">
      <mat-icon>close</mat-icon>
   </span>
   <ng-container *ngIf="suffix">
      <button class="suffix" mat-icon-button matSuffix [matTooltip]="suffix.tooltip">
         <mat-icon *ngIf="suffix.isSvg" [svgIcon]="suffix.icon"></mat-icon>
         <mat-icon *ngIf="!suffix.isSvg">
            {{ suffix.icon }}
         </mat-icon>
      </button>
   </ng-container>
   <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="onOptionSelect($event)"
   >
      <mat-option
         *ngFor="let teamMember of filtered$ | async"
         [value]="teamMember"
         [disabled]="!isActive(teamMember)"
      >
         {{ teamMember.lastName }}, {{ teamMember.firstName }}
      </mat-option>
   </mat-autocomplete>
</mat-form-field>
