import { Injectable } from '@angular/core';
import { FeatureFlags, FeatureFlag } from '../state/internal-tools.reducer';
import { Observable } from 'rxjs';
import { featureFlagSelectors } from '../state/internal-tools.selectors';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { map } from 'rxjs/operators';
import { InternalToolsActions } from '../state/internal-tools.actions';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
   featureFlags$: Observable<FeatureFlags> = this.store.pipe(
      select(featureFlagSelectors.getFeatureFlags)
   );

   featureList$: Observable<FeatureFlag[]>;

   constructor(private store: Store<State>) {
      this.featureList$ = this.featureFlags$.pipe(
         map((featureFlags) => {
            const list = [];
            if (featureFlags) {
               Object.keys(featureFlags).forEach((key) => {
                  const featureFlag = { ...featureFlags[key], key };
                  list.push(featureFlag);
               });
            }
            return list;
         })
      );
   }

   isEnabled(key: string): Observable<boolean> {
      return this.featureFlags$.pipe(
         map((flags) => {
            const flag = flags[key];
            if (flag) {
               return flag.enabled;
            } else {
               return false;
            }
         })
      );
   }

   toggleFeatureFlag(key: string) {
      this.store.dispatch(InternalToolsActions.ToggleFeatureFlag({ key }));
   }

   syncFeatureFlags() {
      this.store.dispatch(InternalToolsActions.SyncFeatureFlags());
   }
}
