import {
   Component,
   EventEmitter,
   inject,
   Input,
   Output,
   SimpleChanges,
   ViewChild,
} from '@angular/core';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { ManagerNotesService } from '@app/team/services/manager-notes.service';
import { ManagerNotes } from '@entities/manager-notes';
import { Task } from '@entities/task';
import { UserComment } from '@entities/user-comment';
import { Observable, take } from 'rxjs';

@Component({
   selector: 'app-manager-notes',
   templateUrl: './manager-notes.component.html',
   styleUrls: ['./manager-notes.component.scss'],
})
export class ManagerNotesComponent {
   @Input() teamMemberId: string;
   @Input() managerId: string;
   @Output() setCommentsComponent = new EventEmitter<UserCommentsComponent>();
   @ViewChild(UserCommentsComponent) commentsComponent: UserCommentsComponent;

   loaded = false;
   managerNotes?: ManagerNotes;

   private managerNotesService = inject(ManagerNotesService);
   private tasksFacade = inject(OrgBuilderFacade);

   tasks$: Observable<Task[]> = this.tasksFacade.tasks$;

   ngOnInit() {
      this.getNotes();
   }

   ngOnChanges() {
      if (this.commentsComponent) {
         console.log('component found');
      }
   }

   commentsInitialized(component: UserCommentsComponent) {
      this.setCommentsComponent.emit(component);
   }

   getNotes() {
      if (this.teamMemberId && this.managerId) {
         this.loaded = false;
         this.managerNotesService
            .get(this.managerId, this.teamMemberId)
            .pipe(take(1))
            .subscribe((notes) => {
               this.managerNotes = notes;
               this.loaded = true;
            });
      }
   }

   createNotes() {
      const newNotes: Partial<ManagerNotes> = {
         managerId: this.managerId,
         teamMemberId: this.teamMemberId,
         notes: [],
      };
      this.managerNotesService.save(newNotes).then(() => {
         this.getNotes();
      });
   }

   saveNotes(notes: UserComment[]) {
      const notesToSave = {
         ...this.managerNotes,
         notes,
      };
      this.managerNotesService.save(notesToSave);
   }
}
