import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { Observable, of } from 'rxjs';

@Component({
   selector: 'app-subnav',
   templateUrl: './subnav.component.html',
   styleUrls: ['./subnav.component.scss'],
})
export class SubnavComponent implements OnInit {
   @Input() config: any;
   @Input() route: any;
   @Input() active: boolean;
   @Input() expanded: boolean;

   @ViewChild('subnavMenu') subnavMenu: ElementRef<HTMLElement>;

   constructor(private featureFlagService: FeatureFlagService) {}

   ngOnInit() {}

   getSubRoute(subroute: string) {
      return `${this.route}/${subroute}`;
   }

   showMenu() {
      if (!this.expanded || (!this.active && this.config.children && this.config.children.length)) {
         this.subnavMenu.nativeElement.style.display = 'block';
      }
   }

   checkHideMenu(event: MouseEvent) {
      if (!this.mouseIsInMenu(event.clientX, event.clientY)) {
         this.hideMenu();
      }
   }

   clickEnd(event: MouseEvent) {
      if (event.button === 0) {
         this.hideMenu();
      }
   }

   mouseIsInMenu(x: number, y: number) {
      const mainRoute = document.getElementById('main-route');
      const rect = mainRoute.getBoundingClientRect();
      const el = document.elementFromPoint(x, y);
      return el && el.classList.contains('subnav-menu-link');
   }

   hideMenu() {
      this.subnavMenu.nativeElement.style.display = 'none';
   }

   showChild(child): Observable<boolean> {
      if (child.featureFlag) {
         return this.featureFlagService.isEnabled(child.featureFlag);
      } else {
         return of(true);
      }
   }
}
