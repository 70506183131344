import { inject, Injectable } from '@angular/core';
import { State } from '@app/app.state';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { FirestoreService } from '@app/shared/services/firestore.service';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { ManagerNotes } from '@entities/manager-notes';
import { select, Store } from '@ngrx/store';
import { from, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class ManagerNotesService {
   readonly COLLECTION_NAME = 'managerNotes';
   private firestore: FirestoreService = inject(FirestoreService);
   private store: Store<State> = inject(Store);
   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<ManagerNotes>();

   constructor() {
      this.orgEntityService.init(this.orgId$, this.COLLECTION_NAME);
   }

   get(managerId: string, teamMemberId: string): Observable<ManagerNotes> {
      return this.orgId$.pipe(
         take(1),
         switchMap(async (orgId) => {
            const collection = this.firestore.collection(
               COLLECTION_NAMES.ORGANIZATIONS,
               orgId,
               this.COLLECTION_NAME
            );
            if (collection) {
               const query = this.queryFilters(managerId, teamMemberId);
               const results = (await this.firestore.queryCollection(collection, ...query)).docs;
               if (results.length > 0) {
                  return results[0].data() as ManagerNotes;
               } else {
                  return undefined;
               }
            } else {
               return undefined;
            }
         })
      );
   }

   save(entity: Partial<ManagerNotes>) {
      return this.orgEntityService.save(entity);
   }

   private queryFilters(managerId: string, teamMemberId: string) {
      return [
         this.firestore.where('teamMemberId', '==', teamMemberId),
         this.firestore.where('managerId', '==', managerId),
      ];
   }
}
