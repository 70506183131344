import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { TeamMember } from '@entities/team-member';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TeamMemberStatus } from '@entities/enums/team-member-status';
import { Router, ActivatedRoute } from '@angular/router';
import { Department } from '@entities/department';
import { TeamFacade, TaskAssignTo } from '@app/team/state/team.facade';
import { Task } from '@entities/task';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { CsvService } from '@app/shared/services/csv.service';
import {
   AssignedTaskViewModel,
   TaskDocumentationViewModel,
} from '@app/documentation/state/documentation.models';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';
import { TrainingPlan } from '@entities/training-plan';
import { Competency } from '@entities/competency';
import { deepCopy } from '@app/utilities/deep-copy';
import { RoleGuard } from '@app/admin/services/role.guard';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { TaskRating } from '@entities/task-rating';
import { CompetencyLevel } from '@entities/competency-level';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { UserRole } from '@entities/enums/user-role';
import { AppAreas } from '@entities/enums/app-areas';
import { DepartmentFunction } from '@entities/department-function';
import { UserCommentsComponent } from '@app/shared/components/user-comments/user-comments.component';
import { AuthUserService } from '@app/auth/services/auth-user.service';

@Component({
   selector: 'app-team-member',
   templateUrl: './team-member.component.html',
   styleUrls: ['./team-member.component.scss'],
})
export class TeamMemberComponent implements OnInit, OnDestroy, SaveChangesComponent {
   teamMember$: Observable<TeamMember>;
   showTabs$: Observable<boolean>;
   destroyed$ = new Subject<boolean>();
   departments$: Observable<Department[]>;
   tasks$ = new BehaviorSubject<AssignedTaskViewModel[]>([]);
   competencyLevels$: Observable<CompetencyLevel[]>;
   showTrainingPlans$: Observable<boolean>;
   showManagerNotes$: Observable<boolean>;
   isAdmin$: Observable<boolean> = this.userOrgFacade.currentUserIsAdmin$;

   tasks: AssignedTaskViewModel[];
   trainingPlans: TrainingPlan[] = [];
   taskRatings: TaskRating[] = [];
   competencyLevels: CompetencyLevel[] = [];
   teamMemberId: string;
   managerId: string;

   commentsComponent?: UserCommentsComponent;

   teamMemberStatus = TeamMemberStatus;
   statusKeys = Object.keys(TeamMemberStatus)
      .filter((key) => !isNaN(+key))
      .map((key) => +key);
   form: UntypedFormGroup;

   canEdit = false;
   saving = false;
   isAdmin = false;
   activeTab = 0;

   competencyFeatureFlag$: Observable<boolean>;

   adminIcon = {
      icon: 'account-cog',
      isSvg: true,
      tooltip: 'Only admins can update manager information',
   };

   constructor(
      private router: Router,
      private teamFacade: TeamFacade,
      private route: ActivatedRoute,
      private csvService: CsvService,
      private featureFlagService: FeatureFlagService,
      private roleGuard: RoleGuard,
      private userOrgFacade: UserOrgFacade,
      private authUserService: AuthUserService
   ) {}

   ngOnInit() {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         firstName: new UntypedFormControl(),
         lastName: new UntypedFormControl(),
         title: new UntypedFormControl(),
         primaryDepartmentId: new UntypedFormControl(),
         status: new UntypedFormControl(),
         notes: new UntypedFormControl(),
         overview: new UntypedFormControl(),
         managerId: new UntypedFormControl(),
      });

      this.competencyFeatureFlag$ = this.featureFlagService.isEnabled(FeatureFlagKeys.COMPETENCIES);
      this.teamMember$ = this.teamFacade.selectedTeamMember$;
      this.competencyLevels$ = this.teamFacade.competencyLevels$;

      this.teamMember$
         .pipe(
            takeUntil(this.destroyed$),
            switchMap((teamMember) =>
               this.roleGuard.canEdit([], AppAreas.Team, [teamMember?.id, teamMember?.managerId])
            )
         )
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
            if (this.form) {
               if (this.canEdit) {
                  this.form.enable();
               } else {
                  this.form.disable();
               }
            }
         });

      this.teamMember$.pipe(takeUntil(this.destroyed$)).subscribe((teamMember) => {
         if (teamMember) {
            this.teamMemberId = teamMember.id;
            this.managerId = teamMember.managerId;
            if (!this.form.dirty) {
               this.form.patchValue(teamMember);
               this.form.markAsPristine();
               this.saving = false;
            }
            this.removeDuplicateAssignedTasks(teamMember);
            this.setTrainingPlans(teamMember);
            // this.setTaskRatings(teamMember);
         } else {
            this.form.reset();
            this.form.get('status').setValue(TeamMemberStatus.Active);
         }
      });
      combineLatest([this.teamMember$, this.competencyLevels$])
         .pipe(takeUntil(this.destroyed$))
         .subscribe(([teamMember, competencyLevels]) => {
            this.competencyLevels = competencyLevels;
            this.setTaskRatings(teamMember);
         });
      this.departments$ = this.teamFacade.departments$;
      this.teamFacade.assignedTasks$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.tasks = tasks;
         this.tasks$.next(this.tasks);
      });
      this.showTabs$ = this.teamMember$.pipe(
         map((teamMember) => {
            if (teamMember && teamMember.id) {
               return true;
            } else {
               return false;
            }
         })
      );
      this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['teamMemberId'] && params['teamMemberId'] != 'new') {
            this.teamFacade.selectTeamMember(params['teamMemberId']);
         }
      });

      this.route.queryParams.pipe(take(1)).subscribe((queryParams) => {
         if (queryParams['tab']) {
            const tab = +queryParams['tab'];
            this.activeTab = isNaN(tab) ? 0 : tab;
         }
      });

      this.showTrainingPlans$ = combineLatest([
         this.userOrgFacade.currentUser$,
         this.teamMember$,
      ]).pipe(
         map(([currentUser, teamMember]) => {
            if (currentUser.role === UserRole.Admin || currentUser.isAdmin) {
               return true;
            } else {
               return (
                  currentUser.teamMemberId === teamMember.id ||
                  currentUser.teamMemberId === teamMember.managerId
               );
            }
         })
      );

      this.showManagerNotes$ = combineLatest([
         this.featureFlagService.isEnabled(FeatureFlagKeys.MANAGER_NOTES),
         this.showTrainingPlans$,
         this.authUserService.currentUser$,
      ]).pipe(
         map(([featureFlag, showTrainingPlans, currentUser]) => {
            return currentUser.isInternalUser || (featureFlag && showTrainingPlans);
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next(true);
   }

   setTrainingPlans(teamMember: TeamMember) {
      if (teamMember.trainingPlans) {
         this.trainingPlans = deepCopy(teamMember.trainingPlans.filter((p) => p.taskId));
      }
   }

   setTaskRatings(teamMember: TeamMember) {
      if (teamMember?.assignedTasks && this.competencyLevels?.length > 0) {
         this.taskRatings = [];
         teamMember.assignedTasks?.forEach((taskId) => {
            const taskRating = teamMember.taskRatings?.find((r) => r.taskId === taskId);
            if (taskRating) {
               this.taskRatings.push({ ...taskRating });
            } else {
               this.taskRatings.push({
                  rating: 0,
                  taskId: taskId,
                  notes: null,
                  competencyLevelId: this.competencyLevels[0].id,
               });
            }
         });
      }
   }

   removeDuplicateAssignedTasks(teamMember: TeamMember) {
      const assignedTasks = teamMember?.assignedTasks?.length;
      if (assignedTasks > 0) {
         const dedupedAssignedTasks = Array.from(new Set(teamMember.assignedTasks));
         if (dedupedAssignedTasks?.length !== assignedTasks) {
            this.teamFacade.saveTeamMember({ ...teamMember, assignedTasks: dedupedAssignedTasks });
         }
      }
   }

   save() {
      const teamMember: TeamMember = {
         ...this.form.value,
         trainingPlans: this.trainingPlans,
         taskRatings: this.taskRatings,
      };
      this.saving = true;
      this.teamFacade.saveTeamMember(teamMember);
   }

   saveAndNew() {
      const teamMember: TeamMember = this.form.value;
      teamMember.trainingPlans = this.trainingPlans;
      this.saving = true;
      this.teamFacade.saveTeamMember(teamMember, { new: true });
   }

   saveAndClose() {
      const teamMember: TeamMember = this.form.value;
      teamMember.trainingPlans = this.trainingPlans;
      this.saving = true;
      this.teamFacade.saveTeamMember(teamMember, { close: true });
   }

   backToList() {
      history.back();
   }

   delete() {
      const teamMember: TeamMember = this.form.value;
      this.teamFacade.deleteTeamMember(teamMember);
   }

   onAssign() {
      this.teamFacade.assignTasks();
   }

   onUnassign(task: Task) {
      this.teamFacade.unassignTask(task, TaskAssignTo.TEAM_MEMBER);
   }

   viewReport() {
      this.router.navigate(['report'], { relativeTo: this.route });
   }

   getExportFilename() {
      return `${this.form.value.firstName} ${this.form.value.lastName} Task Assignments`;
   }

   exportAssignments() {
      const data = this.tasks.map((viewModel) => ({
         Task: viewModel.task.name,
         Function: viewModel.departmentFunction.name,
         Department: viewModel.department.name,
         'Business Unit': viewModel.businessUnit.name,
      }));
      const filename = `${this.form.value.firstName} ${this.form.value.lastName} Task Assignments`;
      this.csvService.export(data, filename);
   }

   viewTask(taskId: string) {
      this.teamFacade.viewTask(taskId);
   }

   updateTrainingPlans(trainingPlans: TrainingPlan[]) {
      this.trainingPlans = trainingPlans;
      this.form.markAsDirty();
   }

   updateTaskRatings(taskRatings: TaskRating[]) {
      this.taskRatings = taskRatings;
      this.form.markAsDirty();
   }

   updateManager(event: MatAutocompleteSelectedEvent) {
      if (event?.option?.value) {
         this.form.get('managerId').setValue(event.option.value.id);
      } else {
         this.form.get('managerId').reset();
      }
      this.form.markAsDirty();
   }

   completeTrainingPlan(trainingPlan: TrainingPlan) {
      const task = this.tasks.find((t) => t.task.id === trainingPlan.taskId);
      const taskRating = this.taskRatings.find((r) => r.taskId === trainingPlan.taskId);
      if (task && taskRating) {
         if (trainingPlan.targetRatingId) {
            task.taskRating.competencyLevelId = trainingPlan.targetRatingId;
            taskRating.competencyLevelId = trainingPlan.targetRatingId;
            task.taskRating.rating = trainingPlan.targetRating;
            taskRating.rating = trainingPlan.targetRating;
         } else {
            const targetRating = this.competencyLevels.find(
               (level) => level.value === trainingPlan.targetRating
            );
            if (targetRating) {
               task.taskRating.competencyLevelId = targetRating.id;
               taskRating.competencyLevelId = targetRating.id;
               task.taskRating.rating = targetRating.value;
               taskRating.rating = targetRating.value;
            }
         }
         this.tasks = [...this.tasks];
         this.tasks$.next(this.tasks);
         this.form.markAsDirty();
      }
   }

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   setCommentsComponent(component: UserCommentsComponent) {
      this.commentsComponent = component;
   }

   setActiveTab(activeTab: number) {
      this.activeTab = activeTab;
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: { tab: activeTab },
         queryParamsHandling: 'merge',
      });
   }
}
