import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TaskService } from '@app/org-builder/services/task.service';
import { Task } from '@entities/task';
import { UntypedFormControl } from '@angular/forms';

@Component({
   selector: 'app-task-autocomplete',
   templateUrl: './task-autocomplete.component.html',
   styleUrls: ['./task-autocomplete.component.scss'],
})
export class TaskAutocompleteComponent implements OnInit, OnDestroy {
   @Input() class: any;
   @Input() placeholder: string;
   @Input() formCtrl: UntypedFormControl;

   @Output() optionSelected: EventEmitter<MatAutocompleteSelectedEvent> = new EventEmitter();

   tasks$: Observable<Task[]>;
   filtered$: Observable<Task[]>;
   focus$ = new BehaviorSubject<boolean>(false);
   filter$ = new BehaviorSubject<any>('');
   selected = false;

   private destroyed$ = new Subject<void>();

   constructor(private taskService: TaskService) {}

   ngOnInit() {
      this.tasks$ = this.taskService.entities$;
      if (!this.formCtrl) {
         this.formCtrl = new UntypedFormControl();
      }
      if (this.formCtrl.value) {
         this.selected = true;
      }
      this.formCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
         this.filter$.next(val);
      });
      this.filtered$ = combineLatest([
         this.tasks$,
         this.filter$.asObservable(),
         this.focus$.asObservable(),
      ]).pipe(
         map(([tasks, filter, focus]) => {
            if (tasks && filter && !filter.id) {
               return tasks.filter((t) => t.name.toLowerCase().includes(filter.toLowerCase()));
            } else {
               return tasks;
            }
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   displayFn(task?: Task): string | undefined {
      return task ? task.name : undefined;
   }

   onOptionSelect(event: MatAutocompleteSelectedEvent) {
      this.optionSelected.emit(event);
      this.selected = true;
   }

   onFocus() {
      this.focus$.next(true);
   }

   clear() {
      this.formCtrl.reset();
      this.selected = false;
      this.optionSelected.emit(null);
   }
}
