<mat-form-field>
   <input
      matInput
      type="text"
      [formControl]="formCtrl"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      (focus)="onFocus()"
      autocomplete="off"
   />
   <span *ngIf="selected" class="clear-selection" (click)="clear()">
      <mat-icon>close</mat-icon>
   </span>
   <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="onOptionSelect($event)"
   >
      <mat-option *ngFor="let task of filtered$ | async" [value]="task">
         {{ task.name }}
      </mat-option>
   </mat-autocomplete>
</mat-form-field>
